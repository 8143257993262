/*** Header ***/
.carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* background: rgba(0, 29, 35, 0.374); */
    object-fit: cover;
    z-index: 1;
}

.sty{
    filter: brightness(70%);
}

.carousel-control-prev,
.carousel-control-next {
    width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 3rem;
    height: 3rem;
    background-color: var(--dark);
    border: 12px solid var(--dark);
    border-radius: 3rem;
}

@media (max-width: 768px) {
    #header-carousel .carousel-item {
        position: relative;
        min-height: 450px;
    }
    
    #header-carousel .carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.page-header {
    padding-top: 12rem;
    padding-bottom: 6rem;
    background: linear-gradient(rgba(0, 29, 35, .8), rgba(0, 29, 35, .8)), url("/public/carousel-4.jpg") center center no-repeat;
    background-size: cover;
}

.page-header .breadcrumb-item+.breadcrumb-item::before {
    color: #999999;
}

.home_para{
    text-align: center;
}

.logo {
    padding: 15px 0;
    text-align: left;
    overflow: hidden;
}

.logo img {
    max-width: 100%;
    max-height: 100px;
}

/*******************************/
/******** Main Para CSS *********/
/*******************************/

.section_para {
    display: block;
    position: relative;
    overflow: hidden;
    padding: 70px 0;
}

.nopad {
    padding: 0;
}

.cac {
    background: linear-gradient(rgba(255, 255, 255, 0.282), rgba(255, 255, 255, 0.293)), url("/public/img/main_para.jpg") center center no-repeat;
    background-size: cover;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.cac:hover .main_para {
    color: #000000 !important;
}

.cac .main_para {
    color: #1e1e1e;
    padding: 20px 8%;
    margin: 0;
    text-transform: capitalize;
}

.categories-area .single-cat {
    border: 1px solid #e1ebf7;
    border-radius: 6px;
    padding: 10px 10px;
    -webkit-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    -ms-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
    position: relative;
    z-index: 1
}

@media (max-width: 575px) {
    .categories-area .single-cat {
        padding: 50px 22px
    }
}

/* .categories-area .single-cat::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    background: var(--bs-btn) !important; 
    transition: .6s;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 6px
} */

.categories-area .offer-1::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    background: linear-gradient(rgba(28, 30, 50, 0.652), rgba(28, 30, 50, 0.575)), url("/public/img/Cooperate.jpeg");
    background-size: cover;
    background-position: center;
    transition: .6s;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 6px;
}

.categories-area .offer-2::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  background: linear-gradient(rgba(28, 30, 50, 0.652), rgba(28, 30, 50, 0.575)), url("/public/img/Passenger_Charter.jpeg");
  background-size: cover;
  background-position: center;
  transition: .6s;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 6px;
}

.categories-area .offer-3::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  background: linear-gradient(rgba(28, 30, 50, 0.652), rgba(28, 30, 50, 0.575)), url("/public/img/Passenger_Charter1.jpeg");
  background-size: cover;
  background-position: center;
  transition: .6s;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 6px;
}

.categories-area .offer-4::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  background: linear-gradient(rgba(28, 30, 50, 0.652), rgba(28, 30, 50, 0.575)), url("/public/img/Flower Dropping.jpeg");
  background-size: cover;
  background-position: center;
  transition: .6s;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 6px;
}

.categories-area .offer-5::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  background: linear-gradient(rgba(28, 30, 50, 0.652), rgba(28, 30, 50, 0.575)), url("/public/img/Leisure and Sports.jpeg");
  background-size: cover;
  background-position: center;
  transition: .6s;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 6px;
}

.categories-area .offer-6::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  background: linear-gradient(rgba(28, 30, 50, 0.652), rgba(28, 30, 50, 0.575)), url("/public/img/Weddings_and_Preshoots.jpg");
  background-size: cover;
  background-position: center;
  transition: .6s;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 6px;
}

.categories-area .offer-7::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  background: linear-gradient(rgba(28, 30, 50, 0.652), rgba(28, 30, 50, 0.575)), url("/public/img/Medical Excavation.jpeg");
  background-size: cover;
  background-position: center;
  transition: .6s;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 6px;
}

.categories-area .offer-8::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  background: linear-gradient(rgba(28, 30, 50, 0.652), rgba(28, 30, 50, 0.575)), url("/public/img/Branding and advertising.jpeg");
  background-size: cover;
  background-position: center;
  transition: .6s;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 6px;
}

.categories-area .offer-9::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  background: linear-gradient(rgba(28, 30, 50, 0.652), rgba(28, 30, 50, 0.575)), url("/public/img/Special Event Product Launches.jpeg");
  background-size: cover;
  background-position: center;
  transition: .6s;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 6px;
}

.categories-area .single-cat .cat-icon span {
    color: #1A76D1;
    font-size: 50px;
    margin-bottom: 30px;
    display: block
}

.categories-area .single-cat .cat-cap h5>a {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 21px;
    display: block;
    -webkit-transition: all .2s ease-out 0s;
    -moz-transition: all .2s ease-out 0s;
    -ms-transition: all .2s ease-out 0s;
    -o-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s;
    font-family: "Barlow", sans-serif
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .categories-area .single-cat .cat-cap h5>a {
        font-size: 26px
    }
}

@media (max-width: 575px) {
    .categories-area .single-cat .cat-cap h5>a {
        font-size: 26px
    }
}

.categories-area .single-cat .cat-cap p {
    margin-bottom: 36px;
    color: #57667e;
    font-size: 16px;
    -webkit-transition: all .2s ease-out 0s;
    -moz-transition: all .2s ease-out 0s;
    -ms-transition: all .2s ease-out 0s;
    -o-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .categories-area .single-cat .cat-cap p {
        font-size: 15px
    }
}

.categories-area .single-cat .cat-cap a {
    color: rgb(26, 18, 134);
    font-size: 22px;
    font-weight: 600;
    -webkit-transition: all .2s ease-out 0s;
    -moz-transition: all .2s ease-out 0s;
    -ms-transition: all .2s ease-out 0s;
    -o-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s
}

.categories-area .single-cat:hover {
    border: 1px solid transparent
}

.categories-area .single-cat:hover::before {
    height: 100%
}

.categories-area .single-cat:hover .cat-icon span {
    color: #fff
}

.categories-area .single-cat:hover .cat-cap h5 {
    color: #fff
}

.categories-area .single-cat:hover .cat-cap p {
    color: #fff
}

.categories-area .single-cat:hover .cat-cap a {
    color: #fff
}

.blog-item img {
    transition: .5s;
}

.blog-item:hover img {
    transform: scale(1.2);
}

@media (min-width: 991.98px) {
    .banner {
        position: relative;
        margin-top: -190px;
        z-index: 1;
    }
}

.glassmorphism-bg {
    background: rgba(29, 29, 29, 0.3);
    border-radius: 15px; /* You can adjust the radius as needed */
    backdrop-filter: blur(10px); /* Adjust the blur effect as needed */
    border: 1px solid rgba(255, 255, 255, 0.18); /* Adjust the border style */
    padding: 20px; /* Adjust padding as needed */
}

.glassmorphism-fd {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 15px; /* You can adjust the radius as needed */
    backdrop-filter: blur(10px); /* Adjust the blur effect as needed */
    padding: 20px; /* Adjust padding as needed */
}

.glassmorphism-ty {
    background: rgba(0, 0, 0, 0.466);
    border-radius: 6px; /* You can adjust the radius as needed */
    backdrop-filter: blur(10px); /* Adjust the blur effect as needed */
    padding: 20px; /* Adjust padding as needed */
}

.get-started .content {
  padding: 30px 0;
}

.get-started .content h3 {
  font-size: 36px;
  color: var(--color-secondary);
  font-weight: 600;
  margin-bottom: 25px;
  padding-bottom: 25px;
  position: relative;
}

.get-started .content h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 4px;
  background: var(--color-primary);
  left: 0;
  bottom: 0;
}

.get-started .content p {
  font-size: 14px;
}

.get-started .php-email-form {
  background: #fff;
  padding: 30px;
  height: 100%;
}

@media (max-width: 575px) {
  .get-started .php-email-form {
    padding: 20px;
  }
}

.get-started .php-email-form h3 {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.get-started .php-email-form p {
  font-size: 14px;
  margin-bottom: 20px;
}

.get-started .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #df1529;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.get-started .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #059652;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.get-started .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.get-started .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #059652;
  border-top-color: #fff;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.get-started .php-email-form input,
.get-started .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 0;
}

.get-started .php-email-form input:focus,
.get-started .php-email-form textarea:focus {
  border-color: var(--color-primary);
}

.get-started .php-email-form input {
  padding: 12px 15px;
}

.get-started .php-email-form textarea {
  padding: 12px 15px;
}

.get-started .php-email-form button[type=submit] {
  background: var(--color-primary);
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.get-started .php-email-form button[type=submit]:hover {
  background: rgba(254, 185, 0, 0.8);
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Beacome a member */

.get-started {
    background: #e5e5e5;
  }

  .get-started .php-email-form {
    background: #fff;
    padding: 30px;
    height: 100%;
  }
  
  @media (max-width: 575px) {
    .get-started .php-email-form {
      padding: 20px;
    }
  }
  
  .get-started .php-email-form h3 {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .get-started .php-email-form p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .get-started .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #df1529;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .get-started .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #059652;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .get-started .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
  }
  
  .get-started .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #059652;
    border-top-color: #fff;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  
  .get-started .php-email-form input,
  .get-started .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 0;
  }
  
  .get-started .php-email-form input:focus,
  .get-started .php-email-form textarea:focus {
    border-color: #50006C;
  }
  
  .get-started .php-email-form input {
    padding: 12px 15px;
  }
  
  .get-started .php-email-form textarea {
    padding: 12px 15px;
  }
  
  .get-started .php-email-form button[type=submit] {
    background: #50006C;
    border: 0;
    padding: 10px 30px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
  }
  
  .get-started .php-email-form button[type=submit]:hover {
    background: #0d6efd;
  }
  
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }