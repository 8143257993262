.page-header_destination {
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(rgba(28, 30, 50, .9), rgba(28, 30, 50, .9)), url("/public/img/C01.webp");
    background-attachment: fixed;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.favourite-place .single-place .place-img {
    overflow: hidden;
    border-radius: 6px 6px 0 0
}

.favourite-place .single-place .place-img img {
    width: 100%;
    -webkit-transform: scale 1;
    -moz-transform: scale 1;
    -ms-transform: scale 1;
    -o-transform: scale 1;
    transform: scale 1;
    transition: all 0.5s ease-out 0s
}

.favourite-place .single-place .place-cap {
    padding: 28px 40px;
    border: 1px solid #f0f1f2;
    border-top: 0
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .favourite-place .single-place .place-cap {
        padding: 28px 18px
    }
}

@media (max-width: 767px) {
    .favourite-place .single-place .place-cap {
        padding: 28px 18px
    }
}

.favourite-place .single-place .place-cap .place-cap-top {
    border-bottom: 1px solid #f6f7f7;
    margin-bottom: 24px
}

.favourite-place .single-place .place-cap .place-cap-top span {
    margin-bottom: -5px;
    display: inline-block
}

.favourite-place .single-place .place-cap .place-cap-top span i {
    color: #ffa800;
    font-size: 18px;
    margin-right: 10px
}

.favourite-place .single-place .place-cap .place-cap-top span span {
    font-family: "Barlow Condensed";
    color: #939ca3;
    font-size: 16px
}

.favourite-place .single-place .place-cap .place-cap-top h3 a {
    font-weight: 600;
    font-size: 24px;
    color: #072740;
    line-height: 1.2;
    margin-bottom: 20px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .favourite-place .single-place .place-cap .place-cap-top h3 a {
        font-size: 23px
    }
}

.favourite-place .single-place .place-cap .place-cap-top h3 a:hover {
    color: #014b85
}

.favourite-place .single-place .place-cap .place-cap-top p.dolor {
    color: #4cafa4;
    font-weight: 600;
    font-family: "Barlow Condensed";
    font-size: 20px;
}

.favourite-place .single-place .place-cap .place-cap-top p.dolor span {
    color: #99a1a7;
    font-size: 16px
}

.favourite-place .single-place .place-cap .place-cap-bottom ul li {
    display: inline-block;
    margin-right: 18px;
    color: #9da5ab
}

.favourite-place .single-place .place-cap .place-cap-bottom ul li i {
    padding-right: 8px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .video-area {
        padding-top: 150px;
        padding-bottom: 150px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .video-area {
        padding-top: 100px;
        padding-bottom: 100px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .video-area {
        padding-top: 100px;
        padding-bottom: 100px
    }
}

@media (max-width: 767px) {
    .video-area {
        padding-top: 100px;
        padding-bottom: 100px
    }
}

.video-area .video-caption .video-icon {
    display: inline-block;
    border: 1px solid #8fa1b6;
    padding: 14px;
    margin-bottom: 65px
}

.video-area .video-caption .video-icon a {
    background: #fde02f;
    color: #000000;
    width: 95px;
    height: 95px;
    display: inline-block;
    text-align: center;
    line-height: 95px;
    border-radius: 3px
}

@media (max-width: 767px) {
    .video-area .video-caption .video-icon a {
        width: 80px;
        height: 80px;
        line-height: 80px
    }
}

.video-area .video-caption .pera1 {
    font-family: "Barlow Condensed";
    color: #fff;
    text-transform: uppercase;
    text-align: center;
}

.video-area .video-caption .pera2 {
    font-family: "Barlow Condensed";
    color: #fff;
    font-weight: 500;
    font-size: 60px;
    line-height: 1.1;
    text-align: center;
}

@media (max-width: 767px) {
    .video-area .video-caption .pera2 {
        font-size: 27px
    }
}

.video-area .video-caption .pera3 {
    font-family: "Barlow Condensed";
    color: #fff;
    font-weight: 500;
    font-size: 60px;
    line-height: 1.1;
    text-align: center;
}

@media (max-width: 767px) {
    .video-area .video-caption .pera3 {
        font-size: 28px
    }
}

.video-bg {
    background-size: cover;
    background-repeat: no-repeat
}

.pt-200 {
    padding-top: 200px
}

.pb-200 {
    padding-bottom: 200px
}

.video-area p {
    font-family: "Roboto";
    color: #506172;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 15px;
    font-weight: normal
}

.img-twice::before {
    position: absolute;
    content: "";
    width: 60%;
    height: 80%;
    top: 10%;
    left: 20%;
    background: #ffffff;
    border: 25px solid #efefef;
    border-radius: 6px;
    z-index: -1;
}

.nav-pillsz .nav-links {
    background: none;
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-pillsz .nav-links.active,
.nav-pillsz .show>.nav-links {
    color: #fff;
    background-color: #efefef
}

.nav-links {
    display: block;
    padding: .5rem 1rem;
    color: #B78D65;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .nav-links {
        transition: none
    }
}

.nav-links:hover,
.nav-links:focus {
    color: #927151
}

.nav-links.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-links {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px
}

.nav-tabs .nav-links:hover,
.nav-tabs .nav-links:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
    isolation: isolate
}

.nav-tabs .nav-links.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-links.active,
.nav-tabs .nav-item.show .nav-links {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-links {
    background: none;
    border: 0;
    border-radius: 0px
}

.nav-pills .nav-links.active,
.nav-pills .show>.nav-links {
    color: #fff;
    background-color: #B78D65
}

.nav-fill>.nav-links,
.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified>.nav-links,
.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.nav-fill .nav-item .nav-links,
.nav-justified .nav-item .nav-links {
    width: 100%
}


