.butt-on{
    margin: 30px 0px 30px 0px ;
    text-align: center;
}

.edit-margin label{
    font-family: 'Times New Roman', Times, serif;
}
.newsletter{
  border: 2px solid black;
  margin-top: 50px;
}
.contact1{
    border: 2px solid black;
    padding: 40px;
    margin-bottom: 100px;
}

.page-header_contact {
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(rgba(28, 30, 50, .9), rgba(28, 30, 50, .9)), url("/public/img/Contactus.jpg");
    background-attachment: fixed;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-darkz {
    background-color: #dfdfdf!important;
}

.bg-lightz {
    background-color: #F8F8F9 !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}