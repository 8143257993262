  /* ------About */
  .About {
      overflow: hidden;
  }

  .margin {
      margin: 100px;
  }

  .margin1 {
      position: relative;
      text-align: center;
      color: white;
  }

  .img-title {
      top: 25%;
      left: 20%;
      transform: translate(-50%, -50%);
  }

  .img-title h1 {
      font-size: 80px;
      font-family: 'Times New Roman', Times, Helvetica, sans-serif !important;
  }

  .we-core {
      font: italic small-caps bold 32px cursive;
      margin-left: 25px;
  }

  .page-header_about {
      height: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: linear-gradient(rgba(28, 30, 50, .9), rgba(28, 30, 50, .9)), url("/public/img/about7.jpg");
      background-attachment: fixed;
      object-fit: cover;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
  }

  @media (max-width: 991.98px) {
      .page-header_about {
          height: 300px;
      }
  }

  .img-video {
      background-position: top center !important;
  }


  @media (max-width: 767.98px) {
      .img-video {
          height: 400px;
      }
  }

  .img {
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
  }



  .align-self-stretch {
      -ms-flex-item-align: stretch !important;
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important;
  }

  .align-items-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
  }

  .icon-video {
      width: 80px;
      height: 80px;
      background: #f79f24;
      -webkit-animation: pulse 2s infinite;
      animation: pulse 2s infinite;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
  }

  .icon-video span {
      font-size: 20px;
      color: #fff;
  }

  @-webkit-keyframes pulse {
      0% {
          -webkit-box-shadow: 0 0 0 0 rgba(247, 159, 36, 0.4);
      }

      70% {
          -webkit-box-shadow: 0 0 0 30px rgba(247, 159, 36, 0);
      }

      100% {
          -webkit-box-shadow: 0 0 0 0 rgba(247, 159, 36, 0);
      }
  }

  @keyframes pulse {
      0% {
          -moz-box-shadow: 0 0 0 0 rgba(247, 159, 36, 0.4);
          -webkit-box-shadow: 0 0 0 0 rgba(247, 159, 36, 0.4);
          box-shadow: 0 0 0 0 rgba(247, 159, 36, 0.4);
      }

      70% {
          -moz-box-shadow: 0 0 0 30px rgba(247, 159, 36, 0);
          -webkit-box-shadow: 0 0 0 30px rgba(247, 159, 36, 0);
          box-shadow: 0 0 0 30px rgba(247, 159, 36, 0);
      }

      100% {
          -moz-box-shadow: 0 0 0 0 rgba(247, 159, 36, 0);
          -webkit-box-shadow: 0 0 0 0 rgba(247, 159, 36, 0);
          box-shadow: 0 0 0 0 rgba(247, 159, 36, 0);
      }
  }

  #videoModal .modal-dialog {
      position: relative;
      max-width: 800px;
      margin: 60px auto 0 auto;
  }

  #videoModal .modal-body {
      position: relative;
      padding: 0px;
  }

  #videoModal .close {
      position: absolute;
      width: 30px;
      height: 30px;
      right: 0px;
      top: -30px;
      z-index: 999;
      font-size: 30px;
      font-weight: normal;
      color: #FFFFFF;
      background: #000000;
      opacity: 1;
  }

.subheading {
    font-size: 15px;
    display: block;
    font-weight: 700;
    color: #330099;
    text-transform: uppercase;
    letter-spacing: 2px;
}


.team-item img {
    position: relative;
    top: 0;
    transition: .5s;
}

/* .team-item:hover img {
    top: -30px;
} */

.team-item .team-text {
    position: relative;
    transition: .5s;
}

.team-item .text-primary {
    text-align: center;
}

.team-item .team-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1a76d1;
    transform: scale(0);
    transition: .5s;
}

.team-item:hover .team-overlay {
    transform: scale(1);
}

.team-item .team-overlay .btn {
    color: #1a76d1;
    background: #efe5e5;
}

.team-item .team-overlay .btn:hover {
    color: #FFFFFF;
    background: #1c4369;
}

.carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: rgba(21, 36, 64, .7); */
    z-index: 1;
  
}

@media (max-width: 576px) {
    .carousel-caption h5 {
        font-size: 14px;
        font-weight: 500 !important;
    }

    .carousel-caption h1 {
        font-size: 30px;
        font-weight: 600 !important;
    }
}

.carousel-control-prev,
.carousel-control-next {
    width: 10%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 3rem;
    height: 3rem;
}

.team-item:hover,
.owl-item.center .team-item {
    background: #1a76d1;
}

.team-item h4 {
    transition: .5s;
}

.owl-item.center .team-item h4,
.owl-item.center .rent-item h4 {
    color: #1a76d1;
}

.team-item .team-social {
    top: 0;
    left: 0;
    opacity: 0;
    transition: .5s;
    background: #1a76d1;
}

.owl-item.center .team-item .team-social,
.owl-item.center .rent-item {
    background: #1a76d1;
}

.team-item:hover .team-social {
    opacity: 1;
    background: #1a76d1;
}

.team-carousel .owl-nav,
.related-carousel .owl-nav {
    position: absolute;
    width: 100%;
    height: 60px;
    top: calc(20% - 30px);
    left: 0;
    display: flex;
    justify-content: space-between;
    z-index: 1;
}

.team-carousel .owl-nav .owl-prev,
.team-carousel .owl-nav .owl-next,
.related-carousel .owl-nav .owl-prev,
.related-carousel .owl-nav .owl-next {
    position: relative;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: #1a76d1;
    font-size: 22px;
    transition: .5s;
}

.team-carousel .owl-nav .owl-prev:hover,
.team-carousel .owl-nav .owl-next:hover,
.related-carousel .owl-nav .owl-prev:hover,
.related-carousel .owl-nav .owl-next:hover {
    background: #1a76d1;
    color: #FFFFFF;
}

/*** Causes ***/
.causes-item .progress {
    height: 5px;
    border-radius: 0;
    overflow: visible;
}

.causes-item .progress .progress-bar {
    position: relative;
    overflow: visible;
    width: 0px;
    border-radius: 0;
    transition: 5s;
}

.causes-item .progress .progress-bar span {
    position: absolute;
    top: -7px;
    right: 0;
    width: 40px;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    background: var(--primary);
    color: #FFFFFF;
}

.causes-item .causes-overlay {
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .5);
    overflow: hidden;
    opacity: 0;
    transition: .5s;
}

.causes-item:hover .causes-overlay {
    height: 100%;
    opacity: 1;
}







.team_member {
    background: #fff;
  }
  
  .team_member .member {
    position: relative;
    box-shadow: 0px 2px 15px rgba(44, 73, 100, 0.08);
    padding: 30px;
    border-radius: 10px;
  }
  
  .team_member .member .pic {
    overflow: hidden;
    width: 180px;
    border-radius: 50%;
  }
  
  .team_member .member .pic img {
    transition: ease-in-out 0.3s;
  }
  
  .team_member .member:hover img {
    transform: scale(1.1);
  }
  
  .team_member .member .member-info {
    padding-left: 30px;
  }
  
  .team_member .member .member-info-sec {
    padding-left: 230px;
  }
  
  .team_member .member h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: #2c4964;
  }
  
  .team_member .member span {
    display: block;
    font-size: 15px;
    padding-bottom: 10px;
    position: relative;
    font-weight: 500;
  }
  
  .team_member .member span::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 1px;
    background: #b2c8dd;
    bottom: 0;
    left: 0;
  }
  
  .team_member .member p {
    margin: 10px 0 0 0;
    font-size: 14px;
  }
  
  .team_member .member .social {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .team_member .member .social a {
    transition: ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    background: #a0bcd5;
  }
  
  .team_member .member .social a i {
    color: #fff;
    font-size: 16px;
    margin: 0 2px;
  }
  
  .team_member .member .social a:hover {
    background: #1977cc;
  }
  
  .team_member .member .social a+a {
    margin-left: 8px;
  }



