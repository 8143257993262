/*** Footer ***/
.footer {
    color: #000000;
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #ffffff;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #ffffff;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    color: var(--light);
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .copyright {
    padding: 25px 0;
    font-size: 15px;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
    color: var(--light);
}

.footer .copyright a:hover {
    color: var(--primary);
}

.back-to-top {
    position: fixed;
    /* display: none; */
    right: 30px;
    bottom: 30px;
    z-index: 99;
}

.top-btn{
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: 6px;
    padding-right: .75rem;
    padding-left: .75rem
}

.d-flex a i {
    transition: transform 0.9s ease-in-out;
}

.d-flex a:hover i {
    transform: rotateX(360deg);
}