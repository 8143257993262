.page-header_news {
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(rgba(28, 30, 50, .9), rgba(28, 30, 50, .9)), url("/public/carousel-9.jpg");
    background-attachment: fixed;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.visiting-hours {
    background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url("/public/carousel-10.jpg") center center no-repeat;
    background-size: cover;
}