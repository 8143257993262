/* Ticket Booking */

.page-header_booking{
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(rgba(28, 30, 50, .9), rgba(28, 30, 50, .9)), url("/public/img/about7.jpg");
    background-attachment: fixed;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.video {
    position: relative;
    padding: 8rem 0 8rem 0;
    background: linear-gradient(rgba(0, 0, 0, 0.775), rgba(0, 0, 0, 0.912)), url("/public/img/ticket.jpg") center center no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.video .btn-play {
    position: relative;
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    border-radius: 50%;
    border: none;
    outline: none;
    padding: 18px 20px 18px 28px;
    margin-bottom: 4rem;
    overflow: hidden;
}

.video .btn-play:before,
.video .btn-play:after {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
    display: block;
    width: 100px;
    height: 100px;
    background: #FF6F0F;
    border-radius: 50%;
}

.video .btn-play:before {
    animation: pulse-border 1500ms ease-out infinite;
    opacity: 1;
}

.video .btn-play:after {
    opacity: 0;
    transition: all 200ms;
}

@keyframes pulse-border {
    0% {
        transform: translate(-50%, -50%) scale(0);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
}

.video .btn-play img {
    position: relative;
    z-index: 3;
    max-width: 100%;
    width: auto;
    height: auto;
}

.video .btn-play span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 32px solid #FF6F0F;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
}

#videoModal {
    z-index: 99999;
}

#videoModal .modal-dialog {
    position: relative;
    max-width: 800px;
    margin: 60px auto 0 auto;
}

#videoModal .modal-body {
    position: relative;
    padding: 0px;
}

#videoModal .close {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0px;
    top: -30px;
    z-index: 999;
    font-size: 30px;
    font-weight: normal;
    color: #FFFFFF;
    background: #000000;
    opacity: 1;
}

.para_one{
    color: #ececec;
    font-size: 20px;
    text-align: center;
}

.bootstrap-datetimepicker-widget.bottom {
    top: auto !important;
}

.bootstrap-datetimepicker-widget .table * {
    border-bottom-width: 0px;
}

.bootstrap-datetimepicker-widget .table th {
    font-weight: 500;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
    padding: 10px;
    border-radius: 2px;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
    background: #FF6F0F;
}

.bootstrap-datetimepicker-widget table td.today::before {
    border-bottom-color: #FF6F0F;
}

/* Three Images  */

.container.border-around {
    border: 1px solid #c5c5c57c; /* Set the border around the container */
    padding: 10px; /* Add some padding inside the container to create space between the border and content */
}

.row {
    margin: 0 auto; /* Center the row horizontally using margin auto */
}

