	/*
  	Flaticon icon font: Flaticon
  	Creation date: 07/04/2020 05:31
  	*/

      @font-face {
        font-family: "Flaticon";
        src: url("/public/fonts/Flaticon.eot");
        src: url("/public/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
             url("/public/fonts/Flaticon.woff2") format("woff2"),
             url("/public/fonts/Flaticon.woff") format("woff"),
             url("/public/fonts/Flaticon.ttf") format("truetype"),
             url("/public/fonts/Flaticon.svg#Flaticon") format("svg");
        font-weight: normal;
        font-style: normal;
      }
      
      @media screen and (-webkit-min-device-pixel-ratio:0) {
        @font-face {
          font-family: "Flaticon";
          src: url("/public/fonts/Flaticon.svg#Flaticon") format("svg");
        }
      }
      
      [class^="flaticon-"]:before, [class*=" flaticon-"]:before,
      [class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
        font-family: Flaticon;
      font-style: normal;
      }
      
      .flaticon-shipped:before { content: "\f100"; }
      .flaticon-ship:before { content: "\f101"; }
      .flaticon-plane:before { content: "\f102"; }
      .flaticon-support:before { content: "\f103"; }
      .flaticon-clock:before { content: "\f104"; }
      .flaticon-place:before { content: "\f105"; }