.condition{
    padding: 1.5rem 5%;
}

.glassmorphism-conditions {
    background: #1A76D1;
    border-radius: 15px; /* You can adjust the radius as needed */
    backdrop-filter: blur(10px); /* Adjust the blur effect as needed */
    border: 1px solid rgba(255, 255, 255, 0.18); /* Adjust the border style */
    padding: 20px; /* Adjust padding as needed */
}

.glassmorphism-pills {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.navitem {
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 8px;
    overflow: hidden;
}

.navlink {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    border: none;
    text-decoration: none;
    color: #fff;
}



