/********** Template CSS **********/
:root {
    --primary: #FF6F0F;
    --secondary: #FFF0E6;
    --light: #F8F8F9;
    --dark: #000000;
}

/*** Button ***/
.btn {
    font-weight: 500;
    transition: .5s;
}

.btn.btn-primary,
.btn.btn-outline-primary:hover {
    color: #FFFFFF;
}

.btn.btn-primary:hover {
    color: #F8F8F9;
    background: rgb(26, 18, 134);
    border-color: rgb(150, 150, 150);
}

.btn.btn-primary-header:hover {
    color: #F8F8F9;
    background: rgb(26, 18, 134);
    border-color: rgb(26, 18, 134);
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}

.logo-img {
     filter: brightness(1.2);
    /* filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.8));  */
    /* width: 120px; 
    height: auto; */
}

/* Add a hover effect to make the logo brighter on hover */
.logo-img:hover {
    filter: brightness(1.2); /* Adjust the brightness on hover */
}

.top-bar a {
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    transition: color 0.3s;
}

.topbaricons {
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    transition: color 0.3s;
}

.rounded-circle {
    border-radius: 50% !important
}

/*** Navbar ***/
.fixed-top {
    transition: .5s;
}

.top-bar {
    height: 35px;
    border-bottom: 2px solid rgba(255, 255, 255, .07);
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}

.navbar .navbar-nav .nav-link {
    margin-right: 30px;
    padding: 25px 0;
    color: #FFFFFF;
    font-weight: 500;
    outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
    color: var(--primary);
}

@media (max-width: 991.98px) {
    .navbar .navbar-nav {
        margin-top: 10px;
        border-top: 1px solid rgba(0, 0, 0, .07);
        background: var(--dark);
    }

    .navbar .navbar-nav .nav-link {
        padding: 10px 0;
    }
}

@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        visibility: hidden;
        top: 100%;
        transform: rotateX(-75deg);
        transform-origin: 0% 0%;
        transition: .5s;
        opacity: 0;
    }

    .navbar .nav-item:hover .dropdown-menu {
        transform: rotateX(0deg);
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }
}