.para-head{
    text-align: center;
    margin: 0px 0px 20px 0px;
} 
p{
    text-align: justify;
    padding: 0px 10px 10px 10px ;
}
.main-heading{
    font-family: Arial,Helvetica,sans-serif !important;
    font-weight:  700!important;
    letter-spacing: 0.02em !important;
    text-align: center;
    margin: 13px 0px 13px 0px;
    
}
.sm-heading{
    text-align: center;
    font-family: Arial,Helvetica,sans-serif !important;
    font-weight:  700!important;
    margin: 50px 0px 50px 0px;
} 
/* .carsouel1-edit{
    margin: auto;
    width: 90%;
}
.carsouel1-edit2{
    margin: auto;
    width: 85%;
} */
